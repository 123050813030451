<template>
    <div class="content">
  
      <el-row class="bgW">
        <el-col :span="22">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
            <el-form-item label="日期">
                <el-date-picker
                    v-model="search.start_time"
                    type="date"
                    class="w130"
                    placeholder="开始日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
                <el-date-picker
                    v-model="search.end_time"
                    type="date"
                    class="w130"
                    placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>

            <el-form-item label="市场" v-if="!userInfo.shop_id">
                <el-select
                    v-model="search.market_id"
                    clearable
                    remote
                    filterable
                    :remote-method="getMarket"
                    placeholder="请输入"
                    class="w120"
                >
                <el-option
                    v-for="item in options.market_id"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-form-item>

            <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
                <el-select
                v-model="search.shop_id"
                clearable
                remote
                filterable
                :remote-method="remoteMethod"
                placeholder="请输入"
                class="w120"
                >
                <el-option
                    v-for="item in options.shop_id"
                    :key="item.shopId"
                    :label="item.shopName"
                    :value="item.shopId"
                />
                </el-select>
            </el-form-item>

            <el-form-item label="商品名称">
              <el-input
                v-model="search.name"
                maxlength="30"
                placeholder="请输入商品名称"
              />
            </el-form-item>
            <el-form-item label="商品编码">
              <el-input
                v-model="search.kingdee_code"
                maxlength="30"
                placeholder="请输入商品编码"
              />
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="search.status"
                clearable
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in options.status"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" style="text-align: right;">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        </el-col>
      </el-row>
      <div class="accountCon">
        <el-button v-if="!userInfo.shop_id" type="primary" :disabled="!idList.length" class="mb10" size="mini" @click="tapBtn('export')">导出数据</el-button>
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              :data="list"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelectionChange"
            >
              <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
              <ElTableColumn label="销售排名" prop="NO" />
              <ElTableColumn label="商品名称" prop="name" />
              <ElTableColumn label="商品金蝶编码" prop="kingdee_code" />
              
              <ElTableColumn label="销售总金额(单位：元)" prop="sum" />
              <!-- <ElTableColumn label="商品规格"  prop="spec_name" />
              <ElTableColumn label="价格(元)"  prop="goods_price" />
              <ElTableColumn label="货号"  prop="goods_sn" />
            
              <ElTableColumn label="状态">
                <template slot-scope="{ row }">
                  <div v-if="row.status == 1">上架</div>
                  <div v-if="row.status == 2">下架</div>
                </template>
              </ElTableColumn>
              <ElTableColumn label="操作"  fixed="right">
                <template
                  slot-scope="{ row }"
                >
                  <span class="theme mr20" @click="tapBtn('detail',row.id)"> 查看</span>
                  <span class="theme mr20" @click="tapBtn('update',row.id)"> 编辑</span>
                  <span class="peril" @click="tapBtn('del',row.id)"> 删除</span>
                </template>
              </ElTableColumn> -->
            </el-table>
            <!-- <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            /> -->
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import pagination from '@/components/Pagination'
  import { goodsRankingList } from '@/api/goods';
  import { getShopList,getMarketLsit } from "@/api/shop";
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  class Search {
    start_time = ''; // 搜索开始时间
    end_time = ''; // 搜索结束时间
    market_id = ''; // 市场
    shop_id = ''; // 门店
  }
  class Options {
    market_id = [];
    shop_id = [];
    constructor () {
      this.status = [
        { id: 1, name: '销售中' },
        { id: 2, name: '已下架' }
      ] 
    }
  }
  export default {
    name: 'goodsRankingList',
    components: {
      pagination
    },
    data () {
      return {
        search: new Search(), // 检索条件
        options: new Options(), // 选项
        page: new Page(), // 分页
        list: [], // 数据展示
        idList: []
      }
    },
  computed: {
      ...mapGetters(['userInfo']),
    },
    mounted () {
      this.getList()
    },
    methods: {
        // 搜索市场
        getMarket(e) {
            getMarketLsit({ page:1, limit:100000, marketName:e }).then(res => {
                console.log(res);
                this.options.market_id = res;
            })
        },

        // 搜索门店
        remoteMethod(e) {
            getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
                this.options.shop_id = res.list;
            });
        },

        // 获取列表
        getList (option) {
            if (option === 'search') this.page = new Page();
            goodsRankingList({...this.search,...this.page}).then(res => {
                // console.log(res);return;
                this.list = res;
                // this.page.total = res.dataCount;
            })
        },
      // 点击按钮
      tapBtn (type,id) {
         switch (type) {
          case 'detail':
            this.$router.push('./goodsDetail?id=' + id)
            break
          case 'update':
            this.$router.push('./addGoods?id=' + id)
            break
          case 'del':
            this.hint(type,'删除',id)
            break
          case 'export':
            this.hint(type,'导出选中')
            break
        }
      },
      // 提示
      hint(type,text,id){
         this.$confirm(`确定${text}商品吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
              switch (type) {
              case 'del':
              this.delGoods(id)
                break
              case 'export':
                this.getExport()
                break
        }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });          
          });
      },
      // 商品删除
      delGoods(id){
        deleteGoods({id}).then(res => {
              this.$message({
                type: 'success',
                message: res.msg
              });
            this.getList()
            })
      },
      // 导出
      getExport(){
        exportGoods({id:this.idList}).then(res => {
          window.open(res.data.url);
        }) 
      },
  
      // 改变表格选中状态
      handleSelectionChange (val) {
        this.idList = val.map(i => {
          return i.id
        })
      }
  
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  .bgW {
    background: #fff;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .el-form-item {
    margin: 5px  40px  3px 0 ;
  }
  .accountCon{
      background: #fff;
      padding: 10px 0px 80px;
  }
  </style>
  